@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Black.eot');
  src: local('Neue Haas Unica Black'), local('NeueHaasUnica-Black'),
    url('./fonts/NeueHaasUnica-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Black.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Black.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Medium.eot');
  src: local('Neue Haas Unica Medium'), local('NeueHaasUnica-Medium'),
    url('./fonts/NeueHaasUnica-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Medium.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Medium.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-BoldItalic.eot');
  src: local('Neue Haas Unica Bold Italic'), local('NeueHaasUnica-BoldItalic'),
    url('./fonts/NeueHaasUnica-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-BoldItalic.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-BoldItalic.woff') format('woff'),
    url('./fonts/NeueHaasUnica-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ExtBlkIta.eot');
  src: local('Neue Haas Unica ExtBlk Ita'), local('NeueHaasUnica-ExtBlkIta'),
    url('./fonts/NeueHaasUnica-ExtBlkIta.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ExtBlkIta.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ExtBlkIta.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ExtBlkIta.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ExtraBold.eot');
  src: local('Neue Haas Unica Extra Bold'), local('NeueHaasUnica-ExtraBold'),
    url('./fonts/NeueHaasUnica-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ExtraBold.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ExtraBold.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-MediumItalic.eot');
  src: local('Neue Haas Unica Medium Italic'),
    local('NeueHaasUnica-MediumItalic'),
    url('./fonts/NeueHaasUnica-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-MediumItalic.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-MediumItalic.woff') format('woff'),
    url('./fonts/NeueHaasUnica-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ExtraBoldItalic.eot');
  src: local('Neue Haas Unica ExtBd Ita'),
    local('NeueHaasUnica-ExtraBoldItalic'),
    url('./fonts/NeueHaasUnica-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ExtraBoldItalic.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Italic.eot');
  src: local('Neue Haas Unica Italic'), local('NeueHaasUnica-Italic'),
    url('./fonts/NeueHaasUnica-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Italic.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Italic.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Light.eot');
  src: local('Neue Haas Unica Light'), local('NeueHaasUnica-Light'),
    url('./fonts/NeueHaasUnica-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Light.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Light.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ExtLtIta.eot');
  src: local('Neue Haas Unica ExtLt Ita'), local('NeueHaasUnica-ExtLtIta'),
    url('./fonts/NeueHaasUnica-ExtLtIta.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ExtLtIta.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ExtLtIta.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ExtLtIta.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Bold.eot');
  src: local('Neue Haas Unica Bold'), local('NeueHaasUnica-Bold'),
    url('./fonts/NeueHaasUnica-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Bold.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Bold.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ThinItalic.eot');
  src: local('Neue Haas Unica Thin Italic'), local('NeueHaasUnica-ThinItalic'),
    url('./fonts/NeueHaasUnica-ThinItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ThinItalic.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ThinItalic.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-LightItalic.eot');
  src: local('Neue Haas Unica Light Italic'), local('NeueHaasUnica-LightItalic'),
    url('./fonts/NeueHaasUnica-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-LightItalic.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-LightItalic.woff') format('woff'),
    url('./fonts/NeueHaasUnica-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ExtraLight.eot');
  src: local('Neue Haas Unica Extra Light'), local('NeueHaasUnica-ExtraLight'),
    url('./fonts/NeueHaasUnica-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ExtraLight.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ExtraLight.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Regular.eot');
  src: local('Neue Haas Unica Regular'), local('NeueHaasUnica-Regular'),
    url('./fonts/NeueHaasUnica-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Regular.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Regular.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-ExtraBlack.eot');
  src: local('Neue Haas Unica Extra Black'), local('NeueHaasUnica-ExtraBlack'),
    url('./fonts/NeueHaasUnica-ExtraBlack.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-ExtraBlack.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-ExtraBlack.woff') format('woff'),
    url('./fonts/NeueHaasUnica-ExtraBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-BoldItalic_1.eot');
  src: local('Neue Haas Unica Bold Italic'), local('NeueHaasUnica-BoldItalic'),
    url('./fonts/NeueHaasUnica-BoldItalic_1.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-BoldItalic_1.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-BoldItalic_1.woff') format('woff'),
    url('./fonts/NeueHaasUnica-BoldItalic_1.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url('./fonts/NeueHaasUnica-Thin.eot');
  src: local('Neue Haas Unica Thin'), local('NeueHaasUnica-Thin'),
    url('./fonts/NeueHaasUnica-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueHaasUnica-Thin.woff2') format('woff2'),
    url('./fonts/NeueHaasUnica-Thin.woff') format('woff'),
    url('./fonts/NeueHaasUnica-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* *,
::before,
::after {
  box-sizing: border-box;
} */

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
