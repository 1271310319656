.org_dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.org_dropdown_btn {
    background-color: transparent;
    color: #333;
    display: flex;
    align-items: center;
    flex: 1;
}

.org_dropdown_btn:hover {
    color: #555;
}

.org_dropdown_btn span {
    font-size: 16px;
    font-weight: 500;
    margin-right: 3px;
}
.org_dropdown_btn svg {
    width: 16px;
    height: 16px;
}

.org_dropdown_avatar {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100%;
}

/* Dropdown Menu */
.org_dropdown_menu {
    margin: 0;
    padding: 0;
    width: 270px;
    margin: 6px 0;
}
.org_dropdown_menu li {
    padding: 4px 15px 4px 40px;
}
.org_dropdown_menu li:hover {
    background-color: #f7fafc;
    cursor: pointer;
}
