.btn1 {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  color: rgb(60, 66, 87);
  min-height: 24px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  margin: 10px 0 8px !important;
}
​ .btn1:hover {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}
​ .btn2 {
  background: #d6ecff;
  color: #5469d4;
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 500;
  display: inline-flex;
  transition: background-color 0.24s ease, box-shadow 0.24s ease;
  border: 0;
  min-height: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
}
​ .btn2:hover {
  background: #bddcfe;
}
