/* Widget button */
.widget-btn {
  color: #8792a2;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 500;
  position: relative;
}
.widget-btn:hover {
  color: #1a1f36 !important;
  cursor: pointer !important;
}

/* Loading Animation for loader */
.loader-wrapper {
  position: absolute;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.3)
  );
  padding: 80px 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  display: inline-block;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-top: 4px solid #333;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Login */
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  margin-top: 1rem;
}
.login-wrapper h4 {
  font-size: 24px;
  margin: 0;
}
.login-wrapper button {
  background-color: #5369d1;
  padding: 5px 9px;
  border-radius: 4px;
  color: #fff;
}
.login-icon {
  font-size: 4rem;
  color: #5369d1;
}
.login-form {
  width: 440px;
  border: 1px solid #ddd;
  border-top: 4px solid #0d6efd;
  border-radius: 4px;
  padding: 32px 32px 32px 32px;
  margin-top: 24px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.form-control {
  display: block;
  width: 100%;
  margin-top: 6px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Sidebar Active Links */
a.active .repsm {
  color: #5369d1;
  font-weight: 500;
}

/* Filter ClearAll Button */
.Filter-ClearAll__btn {
  color: rgb(60, 66, 87);
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Submenu Using Tippy Js in Table Rows */
.submenu {
  width: 230px;
  list-style: none;
  padding: 0;
  margin: 6px 0;
}
.submenu1 {
  width: 220px;
  list-style: none;
  padding: 0;
  margin: 6px 0;
}

.submenu li {
  font-weight: 500;
  color: #1a1f36;
  /* padding: 5px 20px; */
}
.submenu li:hover {
  cursor: pointer;
  background-color: #f7fafc;
}
.submenu-link {
  display: block;
  padding: 5px 20px;
}
.submenu span.loading-icon {
  display: none;
}
.submenu .submenu-loading span.loading-icon {
  display: inline;
}

/* Tippy Overrides */
.tippy-box {
  box-shadow: 0 0 0 1px rgb(136 152 170 / 10%),
    0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
  border: none !important;
  border-radius: 4px !important;
  overflow: hidden;
}
.tippy-content {
  padding: 0;
}

/* Tabs in Account Settings */
.tabs .line_myprofile {
  left: 0;
  width: 69px;
}
.tabs .line_plans {
  left: 99px;
  width: 33px;
}

/* Modal */
.Modal__close-btn {
  background-color: transparent;
  padding: 0px 5px;
  font-size: 28px;
  color: #333;
  opacity: 0.65;
  transition: 0.25s;
}
.Modal__close-btn:hover {
  opacity: 1;
}
.ReactModal__Overlay {
  background-color: rgba(226, 232, 240, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.25s linear forwards;
}

.ReactModal__Content {
  animation: zoomIn 0.25s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

/* Blurring Feedbacks */
.blur {
  filter: blur(5px) !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Margin-horizontal--auto {
  margin-left: auto;
  margin-right: auto;
}
.alert {
  padding: 1rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert ul {
  padding: 0;
  padding-left: 1.25rem;
  padding-top: 0.5rem;
  margin: 0;
}

/* EXTRAS, TEMP */
.d-none {
  display: none;
}

/* switch styles */
.submenu1::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 20px;
  top: -20px;
  z-index: 999;
  right: 0;
  height: 20px;
  transform: rotate(45deg);
  border: 1px solid #1d72db;
  border-right-color: rgb(18, 118, 240);
  border-bottom-color: rgb(10, 103, 216);
  background-color: rgb(32, 101, 230);
  background-clip: padding-box;
}

.SwitchApp__title h1 {
  white-space: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Ubuntu, sans-serif;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 24px;
  font-weight: 700;
  font-size: 16px;
  color: rgb(26, 31, 54);
  padding: 5px 20px;
}

.Switch_Dropdown {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.Switch_Dropdown li {
  margin-left: -30px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-align: left;
  padding: 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center !important;
}

.Switch_Dropdown .switch_product_title {
  margin-left: 10px;
  margin-top: -4px;
}
