@keyframes skeleton {
    0% {
      background-color: rgba(0, 0, 0, 0.02);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  
  .skeleton {
    border-radius: 3px;
    animation: skeleton 0.5s infinite alternate;
    animation-delay: inherit;
    background-color: rgba(0, 0, 0, 0.02);
}
/**
* Text
*/
.text {
  display: block;
  max-width: 100%;
  height: 1.2em;
  font-size: 1rem;
}
.text:not(:last-child):not(.inline) {
  margin-bottom: 0.25em;
}
.fullWidth {
  width: 100%;
}
.small {
  height: 0.8em;
}
.large {
  height: 2em;
}
/**
* Icon
*/
.icon {
  display: block;
}
.rounded {
  border-radius: 50%;
}
/**
* Common
*/
.inline {
  display: inline-block;
  vertical-align: middle;
}
